<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <h2 class="brand-text text-primary ml-1">CBP</h2>
        </b-link>

        <b-card-title class="mb-1"> Réinitialiser Mot de Passe 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Votre nouveau mot de passe doit être différent des mots de passe précédemment utilisés
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">

            <!-- reset_code -->
            <b-form-group label="Code 8 chiffre réçu via email" label-for="reset_code">
              <validation-provider #default="{ errors }" name="reset_code" vid="reset_code" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="reset_code" v-model="reset_code" :state="errors.length > 0 ? false : null"
                    class="form-control-merge" name="reset_code" placeholder="00000000" />
                </b-input-group>
                <small class="text-danger" v-if="errors.length > 0 ? true : false">Compléter le code de 8 chiffre reçu
                  dans votre boite email</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group label="Nouveau mot de passe" label-for="reset-password-new">
              <validation-provider #default="{ errors }" name="Password" vid="Password" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="reset-password-new" v-model="password"
                    :state="errors.length > 0 ? false : null" class="form-control-merge" name="reset-password-new"
                    placeholder="············" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="password1ToggleIcon" @click="togglePassword1Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger" v-if="errors.length > 0 ? true : false">Compléter votre nouveau mot de
                  passe</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group label-for="reset-password-confirm" label="Nouveau mot de passe(Confirmation)">
              <validation-provider #default="{ errors }" name="Mot de passe (confirmation)" rules="required">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="reset-password-confirm" v-model="cPassword"
                    class="form-control-merge" :state="errors.length > 0 ? false : null" name="reset-password-confirm"
                    placeholder="············" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="password2ToggleIcon" @click="togglePassword2Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger" v-if="errors.length > 0 ? true : false">Compléter encore votre nouveau mot de
                  passe</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button type="submit" variant="primary" block :disabled="loading">
              <LoadingIcon v-if="loading" style="width: 24px; float: center"></LoadingIcon>

              <span v-if="loading == false">Enregistrer les Modifications</span>

            </b-button>

          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'auth-login' }">
            <feather-icon icon="ChevronLeftIcon" /> Rétour vers connexion
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import LoadingIcon from "@core/layouts/components/Loading.vue";

import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";
import axios from "axios";

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    LoadingIcon,
  },
  data() {
    return {
      reset_code: "",
      userEmail: "",
      password: "",
      cPassword: "",
      // validation
      required,
      loading: false,
      LoadingIcon,

      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {

        this.loading = true;

        if (success) {

          let formData = new FormData();
          formData.append("reset_code", this.reset_code);
          formData.append("password", this.password);
          formData.append("cPassword", this.cPassword);

          axios
            .post($themeConfig.app.api_endpoint + `auth/password-change`, formData)
            .then((response) => {

              this.loading = false;

              console.log(response)

              if (response.status == 200) {

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Opération Réussi",
                    text: "Votre nouveau mot de passe a été bien enregistré.",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });

                // Redirect to reset password page
                this.$router.push({ name: "auth-login" });

              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Erreur Survenue.",
                    text: response.data.message,
                    icon: "InfoIcon",
                    variant: "warning",
                  },
                });
              }
            })
            .catch((error) => {
              console.log(error)
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Erreur Exceptionnelle Survenue.",
                  text: error.response.data.message,
                  icon: "InfoIcon",
                  variant: "warning",
                },
              });
            }); 

        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
